import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';
import { elementShape } from 'shapes/vendor';

import Filters from './Filters';

import { FILTERS_DISPLAY_TYPES } from '../facets.constants';

import classNames from './filtersWithContentLayout.module.scss';

const enhancer = compose(
  memo,
);

const FiltersWithContentLayout = ({
  content,
  type,
  filtersComponent,
}) => (
  <div className={cn(classNames.container, classNames[type])}>
    <div className={classNames.drawer}>
      <Filters filtersComponent={filtersComponent} />
    </div>
    <div className={classNames.content}>
      {content}
    </div>
  </div>
);

FiltersWithContentLayout.displayName = 'FiltersWithContentLayout';

FiltersWithContentLayout.propTypes = {
  content: PropTypes.node.isRequired,
  filtersComponent: elementShape.isRequired,
  type: PropTypes.string,
};

FiltersWithContentLayout.defaultProps = {
  type: FILTERS_DISPLAY_TYPES.DEFAULT,
};

export default enhancer(FiltersWithContentLayout);
