import React from 'react';
import { compose, lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { TreeSelect } from 'antd';
import { FormattedMessage } from 'react-intl';
import memoize from 'memoize-one';
import connect from 'react/hoc/connectProxy';

import { getCommunities } from 'redux/communities/actions';
import { selectCommunitiesMap } from 'redux/communities/selectors';
import { architectureBuildingBlockShape } from 'shapes/architectureBuildingBlocks';
import { communityShape } from 'shapes/community';
import withAllArchitectureBuildingBlocks from 'react/business/architectureBuildingBlocks/card/connect/withAllArchitectureBuildingBlocks.connect';

import { FILTERS } from '../../facets.constants';
import messages from '../../facets.messages';

import classNames from './architectureBuildingBlocks.module.scss';

const { TreeNode } = TreeSelect;

const enhancer = compose(
  withAllArchitectureBuildingBlocks,

  connect(
    state => ({
      communities: selectCommunitiesMap(state),
    }),
    { getCommunities },
  ),

  lifecycle({
    /**
     * Load communities list on mount.
     */
    async componentDidMount() {
      await this.props.getCommunities({
        populateDomains: true,
        countProducts: false,
      });
    },
  }),
);

const renderTreeNodes = (
  abbs,
  communities,
) => {
  const tree = [];
  Object.entries(abbs).forEach(([key, value]) => {
    tree.push(
      <TreeNode
        title={communities[key]?.name}
        key={key}
        value={key}
      >
        {value.map(abb => (
          <TreeNode
            title={abb.name}
            key={abb.id}
            value={abb.id}
          />
        ))}
      </TreeNode>,
    );
  });
  return tree;
};

class ArchitectureBuildingBlocks extends React.PureComponent {
  onChange = (values) => {
    const abbs = [];
    Object.entries(this.props.abbs).forEach(([, value]) => {
      abbs.push(...value);
    });

    const checkedValues = values.reduce((map, current) => {
      const communityId = abbs.find(abb => abb.id === current).community;
      map[communityId] = [
        ...(map[communityId] || []),
        current,
      ];
      return map;
    }, {});

    const checkedAbbIds = [];
    const checkedCommunityIds = [];

    Object.entries(checkedValues).forEach(([key, value]) => {
      if (value.length === this.props.abbs[key].length) {
        checkedCommunityIds.push(key);
      } else {
        checkedAbbIds.push(...value);
      }
    });

    // Set the ids into filters.
    this.props.onSetFilters({
      [FILTERS.ARCHITECTURE_BUILDING_BLOCK_IDS]: checkedAbbIds,
      [FILTERS.ABB_COMMUNITY_IDS]: checkedCommunityIds,
    });
  };

  getCheckedKeys = memoize((checkedAbbIds, checkedCommunityIds) => [
    ...checkedAbbIds,
    ...checkedCommunityIds,
  ]);

  renderTreeNodesMemoized = memoize(renderTreeNodes);

  /**
   * @returns {object} JSX.
   */
  render() {
    const {
      filters,
      abbs,
      communities,
    } = this.props;

    return (
      <TreeSelect
        className={classNames.dropdown}
        searchPlaceholder={<FormattedMessage {...messages.FACET_ARCHITECTURE_BUILDING_BLOCKS} />}
        size="large"
        maxTagCount={1}
        maxTagTextLength={13}
        treeCheckable
        onChange={this.onChange}
        value={this.getCheckedKeys(
          filters[FILTERS.ARCHITECTURE_BUILDING_BLOCK_IDS],
          filters[FILTERS.ABB_COMMUNITY_IDS],
        )}
        dropdownStyle={{ maxHeight: '20rem' }}
      >
        {this.renderTreeNodesMemoized(abbs, communities)}
      </TreeSelect>
    );
  }
}

ArchitectureBuildingBlocks.displayName = 'ArchitectureBuildingBlocks';

ArchitectureBuildingBlocks.propTypes = {
  abbs: PropTypes.objectOf(PropTypes.arrayOf(architectureBuildingBlockShape)),
  communities: PropTypes.objectOf(communityShape),
  onSetFilters: PropTypes.func,
  onChange: PropTypes.func,
  filters: PropTypes.shape({
    [FILTERS.ARCHITECTURE_BUILDING_BLOCK_IDS]: PropTypes.arrayOf(PropTypes.string),
    [FILTERS.ABB_COMMUNITY_IDS]: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  getCheckedKeys: PropTypes.func,
};

ArchitectureBuildingBlocks.defaultProps = {
  abbs: {},
  communities: {},
  onSetFilters: noop,
  onChange: noop,
  getCheckedKeys: noop,
};

export default enhancer(ArchitectureBuildingBlocks);
