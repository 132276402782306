import { compose, withProps } from 'recompose';
import { isMaintainedByPlatformOptions } from 'react/business/products/isMaintainedByPlatform/isMaintainedByPlatform.constants';

const mappedFilter = isMaintainedByPlatformOptions.map(option => ({
  id: option.value,
  name: option.label,
}));

export default compose(
  withProps({
    values: mappedFilter,
  }),
);
