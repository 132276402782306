import { compose, withProps } from 'recompose';
import { stagesOptions } from 'react/business/products/stages/stages.constants';

const mappedStages = stagesOptions.map(stage => ({
  id: stage.value,
  name: stage.label,
}));

export default compose(
  withProps({
    values: mappedStages,
  }),
);
