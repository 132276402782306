import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { FormattedMessage } from 'react-intl';
import ControlButton from '../button/ControlButton';

import messages from './resetButton.messages';

const ResetButton = ({ onResetFilters }) => (
  <ControlButton onClick={onResetFilters}>
    <FormattedMessage {...messages.FILTER_RESET} />
  </ControlButton>
);

ResetButton.displayName = 'ResetButton';

ResetButton.propTypes = {
  onResetFilters: PropTypes.func,
};

ResetButton.defaultProps = {
  onResetFilters: noop,
};

export default memo(ResetButton);
