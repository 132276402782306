import { noop } from 'lodash';
import React from 'react';
import memoize from 'memoize-one';

import { TreeSelect } from 'antd';
import {
  activityShape,
  atomicActivityShape,
  macroActivityShape, treeElementShape,
} from 'shapes/businessActivities';

import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { FILTERS } from '../../facets.constants';
import messages from '../../facets.messages';

import classNames from './businessActivities.module.scss';

const { TreeNode } = TreeSelect;

const renderTreeNodes = (
  tree,
  macroActivitiesMap,
  activitiesMap,
  atomicActivitiesMap,
) => (tree || []).map(macroActivity => (
  <TreeNode
    title={macroActivitiesMap[macroActivity.id].name}
    key={macroActivity.id}
    value={macroActivity.id}
  >
    {macroActivity.children.map(activity => (
      <TreeNode title={activitiesMap[activity.id].name} key={activity.id} value={activity.id}>
        {activity.children.map(atomicActivity => (
          <TreeNode
            title={atomicActivitiesMap[atomicActivity.id].name}
            key={atomicActivity.id}
            value={atomicActivity.id}
          />
        ))}
      </TreeNode>
    ))}
  </TreeNode>
));

class BusinessActivities extends React.PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      [FILTERS.MACRO_ACTIVITY_IDS]: PropTypes.arrayOf(PropTypes.string),
      [FILTERS.ACTIVITY_IDS]: PropTypes.arrayOf(PropTypes.string),
      [FILTERS.ATOMIC_ACTIVITY_IDS]: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    onSetFilters: PropTypes.func,
    macroActivitiesMap: PropTypes.objectOf(macroActivityShape).isRequired,
    activitiesMap: PropTypes.objectOf(activityShape).isRequired,
    atomicActivitiesMap: PropTypes.objectOf(atomicActivityShape).isRequired,
    tree: PropTypes.arrayOf(treeElementShape).isRequired,
  };

  static defaultProps = {
    onSetFilters: noop,
  };

  getCheckedKeys = memoize((macroActivityIds, activityIds, atomicActivityIds) => [
    ...macroActivityIds,
    ...activityIds,
    ...atomicActivityIds,
  ]);

  onChange = (values) => {
    const {
      checkedMacroActivityIds,
      checkedActivityIds,
      checkedAtomicActivityIds,
    } = this.props.tree.reduce(
      (result, macroActivity) => {
        const fullyCheckedActivityIds = macroActivity.children.reduce(
          (checkedActivities, activity) => {
            const checkedAtomicActivities = activity.children
              .filter(({ id }) => values.includes(id));

            if (
              checkedAtomicActivities.length
              && checkedAtomicActivities.length === activity.children.length
            ) {
              checkedActivities.push(activity.id);
            } else {
              result.checkedAtomicActivityIds.push(...checkedAtomicActivities.map(a => a.id));
            }
            return checkedActivities;
          },
          [],
        );

        if (
          fullyCheckedActivityIds.length
          && fullyCheckedActivityIds.length === macroActivity.children.length
        ) {
          result.checkedMacroActivityIds.push(macroActivity.id);
        } else {
          result.checkedActivityIds.push(...fullyCheckedActivityIds);
        }

        return result;
      },
      {
        checkedMacroActivityIds: [],
        checkedActivityIds: [],
        checkedAtomicActivityIds: [],
      },
    );

    // And submit.
    this.props.onSetFilters({
      [FILTERS.MACRO_ACTIVITY_IDS]: checkedMacroActivityIds,
      [FILTERS.ACTIVITY_IDS]: checkedActivityIds,
      [FILTERS.ATOMIC_ACTIVITY_IDS]: checkedAtomicActivityIds,
    });
  };

  renderTreeNodesMemoized = memoize(renderTreeNodes);

  /** @returns {object} JSX. */
  render() {
    const {
      filters,
      tree,
      macroActivitiesMap,
      activitiesMap,
      atomicActivitiesMap,
    } = this.props;
    return (
      <TreeSelect
        className={classNames.dropdown}
        maxTagCount={1}
        maxTagTextLength={13}
        onChange={this.onChange}
        searchPlaceholder={<FormattedMessage {...messages.FACET_BUSINESS_ACTIVITIES} />}
        size="large"
        treeCheckable
        value={this.getCheckedKeys(
          filters[FILTERS.MACRO_ACTIVITY_IDS],
          filters[FILTERS.ACTIVITY_IDS],
          filters[FILTERS.ATOMIC_ACTIVITY_IDS],
        )}
      >
        { this.renderTreeNodesMemoized(
          tree,
          macroActivitiesMap,
          activitiesMap,
          atomicActivitiesMap,
        )}
      </TreeSelect>
    );
  }
}

export default BusinessActivities;
