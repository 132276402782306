import * as ArchitectureBuildingBlocksService from 'services/architectureBuildingBlocks';
import { ACTIONS } from './constants';

export const GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY = communityId => ({
  type: ACTIONS.GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY,
  communityId,
});

export const GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY_SUCCESS = (communityId, abbs) => ({
  type: ACTIONS.GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY_SUCCESS,
  communityId,
  abbs,
});

export const GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY_FAILURE = (communityId, error) => ({
  type: ACTIONS.GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY_FAILURE,
  communityId,
  error,
});

export const getArchitectureBuildingBlocksByCommunityId = communityId => async (dispatch) => {
  dispatch(GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY(communityId));

  try {
    const abbs = await ArchitectureBuildingBlocksService.getArchitectureBuildingBlocksByCommunityId(
      communityId,
    );
    return dispatch(GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY_SUCCESS(communityId, abbs));
  } catch (err) {
    return dispatch(GET_ARCHITECTURE_BUILDING_BLOCKS_BY_COMMUNITY_FAILURE(communityId, err));
  }
};

export const GET_ALL_ARCHITECTURE_BUILDING_BLOCKS = () => ({
  type: ACTIONS.GET_ALL_ARCHITECTURE_BUILDING_BLOCKS,
});

export const GET_ALL_ARCHITECTURE_BUILDING_BLOCKS_SUCCESS = abbs => ({
  type: ACTIONS.GET_ALL_ARCHITECTURE_BUILDING_BLOCKS_SUCCESS,
  abbs,
});

export const GET_ALL_ARCHITECTURE_BUILDING_BLOCKS_FAILURE = error => ({
  type: ACTIONS.GET_ALL_ARCHITECTURE_BUILDING_BLOCKS_FAILURE,
  error,
});

export const getAllArchitectureBuildingBlocks = () => async (dispatch) => {
  dispatch(GET_ALL_ARCHITECTURE_BUILDING_BLOCKS());

  try {
    const abbs = await ArchitectureBuildingBlocksService.getAllArchitectureBuildingBlocks();
    return dispatch(GET_ALL_ARCHITECTURE_BUILDING_BLOCKS_SUCCESS(abbs));
  } catch (err) {
    return dispatch(GET_ALL_ARCHITECTURE_BUILDING_BLOCKS_FAILURE(err));
  }
};
