import * as SubsetsService from 'services/subsets';
import { ACTIONS } from './constants';

export const GET_SUBSETS_BY_COMMUNITY = communityId => ({
  type: ACTIONS.GET_SUBSETS_BY_COMMUNITY,
  communityId,
});

export const GET_SUBSETS_BY_COMMUNITY_SUCCESS = (communityId, subsets) => ({
  type: ACTIONS.GET_SUBSETS_BY_COMMUNITY_SUCCESS,
  communityId,
  subsets,
});

export const GET_SUBSETS_BY_COMMUNITY_FAILURE = (communityId, error) => ({
  type: ACTIONS.GET_SUBSETS_BY_COMMUNITY_FAILURE,
  communityId,
  error,
});

export const getSubsetsByCommunityId = communityId => async (dispatch) => {
  dispatch(GET_SUBSETS_BY_COMMUNITY(communityId));
  try {
    const subsets = await SubsetsService.getSubsetsByCommunityId(communityId);
    return dispatch(GET_SUBSETS_BY_COMMUNITY_SUCCESS(communityId, subsets));
  } catch (err) {
    return dispatch(GET_SUBSETS_BY_COMMUNITY_FAILURE(communityId, err));
  }
};

export const GET_ALL_SUBSETS = () => ({
  type: ACTIONS.GET_ALL_SUBSETS,
});

export const GET_ALL_SUBSETS_SUCCESS = subsets => ({
  type: ACTIONS.GET_ALL_SUBSETS_SUCCESS,
  subsets,
});

export const GET_ALL_SUBSETS_FAILURE = error => ({
  type: ACTIONS.GET_ALL_SUBSETS_FAILURE,
  error,
});

export const getAllSubsets = () => async (dispatch) => {
  dispatch(GET_ALL_SUBSETS());

  try {
    const subsets = await SubsetsService.getAllSubsets();
    return dispatch(GET_ALL_SUBSETS_SUCCESS(subsets));
  } catch (err) {
    return dispatch(GET_ALL_SUBSETS_FAILURE(err));
  }
};
