import * as BusinessActivitiesService from 'services/businessActivities';

import { ACTIONS } from './constants';

export const GET_MACRO_ACTIVITIES = filters => ({
  type: ACTIONS.GET_MACRO_ACTIVITIES,
  filters,
});

export const GET_MACRO_ACTIVITIES_SUCCESS = (
  macroActivitiesCount,
  activitiesCount,
  atomicActivitiesCount,
  macroActivities,
  activities,
  atomicActivities,
) => ({
  type: ACTIONS.GET_MACRO_ACTIVITIES_SUCCESS,
  macroActivitiesCount,
  activitiesCount,
  atomicActivitiesCount,
  macroActivities,
  activities,
  atomicActivities,
});

export const GET_MACRO_ACTIVITIES_FAILURE = error => ({
  type: ACTIONS.GET_MACRO_ACTIVITIES_FAILURE,
  error,
});

export const getMacroActivities = (filters = {}) => async (dispatch) => {
  dispatch(GET_MACRO_ACTIVITIES(filters));

  try {
    const {
      macroActivitiesCount,
      activitiesCount,
      atomicActivitiesCount,
      macroActivities,
      activities,
      atomicActivities,
    } = await BusinessActivitiesService.getMacroActivities(filters);
    return dispatch(GET_MACRO_ACTIVITIES_SUCCESS(
      macroActivitiesCount,
      activitiesCount,
      atomicActivitiesCount,
      macroActivities,
      activities,
      atomicActivities,
    ));
  } catch (err) {
    return dispatch(GET_MACRO_ACTIVITIES_FAILURE(err));
  }
};

export const GET_MACRO_ACTIVITY = macroActivityId => ({
  type: ACTIONS.GET_MACRO_ACTIVITY,
  macroActivityId,
});

export const GET_MACRO_ACTIVITY_SUCCESS = macroActivity => ({
  type: ACTIONS.GET_MACRO_ACTIVITY_SUCCESS,
  macroActivity,
});

export const GET_MACRO_ACTIVITY_FAILURE = error => ({
  type: ACTIONS.GET_MACRO_ACTIVITY_FAILURE,
  error,
});

export const getMacroActivity = macroActivityId => async (dispatch) => {
  dispatch(GET_MACRO_ACTIVITY(macroActivityId));

  try {
    const macroActivity = await BusinessActivitiesService.getMacroActivity(macroActivityId);
    return dispatch(GET_MACRO_ACTIVITY_SUCCESS(macroActivity));
  } catch (err) {
    return dispatch(GET_MACRO_ACTIVITY_FAILURE(err));
  }
};

export const GET_ACTIVITIES = filters => ({
  type: ACTIONS.GET_ACTIVITIES,
  filters,
});

export const GET_ACTIVITIES_SUCCESS = (activities, count) => ({
  type: ACTIONS.GET_ACTIVITIES_SUCCESS,
  activities,
  count,
});

export const GET_ACTIVITIES_FAILURE = error => ({
  type: ACTIONS.GET_ACTIVITIES_FAILURE,
  error,
});

export const getActivities = (filters = {}) => async (dispatch) => {
  dispatch(GET_ACTIVITIES(filters));

  try {
    const { activities, count } = await BusinessActivitiesService.getActivities(filters);
    return dispatch(GET_ACTIVITIES_SUCCESS(activities, count));
  } catch (err) {
    return dispatch(GET_ACTIVITIES_FAILURE(err));
  }
};

export const GET_ACTIVITY = activityId => ({
  type: ACTIONS.GET_ACTIVITY,
  activityId,
});

export const GET_ACTIVITY_SUCCESS = activity => ({
  type: ACTIONS.GET_ACTIVITY_SUCCESS,
  activity,
});

export const GET_ACTIVITY_FAILURE = error => ({
  type: ACTIONS.GET_ACTIVITY_FAILURE,
  error,
});

export const getActivity = activityId => async (dispatch) => {
  dispatch(GET_ACTIVITY(activityId));

  try {
    const activity = await BusinessActivitiesService.getActivity(activityId);
    return dispatch(GET_ACTIVITY_SUCCESS(activity));
  } catch (err) {
    return dispatch(GET_ACTIVITY_FAILURE(err));
  }
};

export const GET_ATOMIC_ACTIVITIES = filters => ({
  type: ACTIONS.GET_ATOMIC_ACTIVITIES,
  filters,
});

export const GET_ATOMIC_ACTIVITIES_SUCCESS = (atomicActivities, count) => ({
  type: ACTIONS.GET_ATOMIC_ACTIVITIES_SUCCESS,
  atomicActivities,
  count,
});

export const GET_ATOMIC_ACTIVITIES_FAILURE = error => ({
  type: ACTIONS.GET_ATOMIC_ACTIVITIES_FAILURE,
  error,
});

export const getAtomicActivities = (filters = {}) => async (dispatch) => {
  dispatch(GET_ATOMIC_ACTIVITIES(filters));

  try {
    const { atomicActivities, count } = await BusinessActivitiesService
      .getAtomicActivities(filters);
    return dispatch(GET_ATOMIC_ACTIVITIES_SUCCESS(atomicActivities, count));
  } catch (err) {
    return dispatch(GET_ATOMIC_ACTIVITIES_FAILURE(err));
  }
};

export const GET_ATOMIC_ACTIVITY = atomicActivityId => ({
  type: ACTIONS.GET_ATOMIC_ACTIVITY,
  atomicActivityId,
});

export const GET_ATOMIC_ACTIVITY_SUCCESS = atomicActivity => ({
  type: ACTIONS.GET_ATOMIC_ACTIVITY_SUCCESS,
  atomicActivity,
});

export const GET_ATOMIC_ACTIVITY_FAILURE = error => ({
  type: ACTIONS.GET_ATOMIC_ACTIVITY_FAILURE,
  error,
});

export const getAtomicActivity = atomicActivityId => async (dispatch) => {
  dispatch(GET_ATOMIC_ACTIVITY(atomicActivityId));

  try {
    const atomicActivity = await BusinessActivitiesService.getAtomicActivity(
      atomicActivityId,
    );
    return dispatch(GET_ATOMIC_ACTIVITY_SUCCESS(atomicActivity));
  } catch (err) {
    return dispatch(GET_ATOMIC_ACTIVITY_FAILURE(err));
  }
};
