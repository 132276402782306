import agent from 'services/http';
import { mapSubsetFromServer } from './subsets.mapper';

const e = encodeURIComponent;

/**
 * GET /api/subsets/community/{communityId}.
 * Get the list of subsets for a community.
 *
 * @async
 * @param {*} communityId - Id of the community.
 * @returns {Array<object>} Subsets.
 */
export const getSubsetsByCommunityId = async (communityId) => {
  const { body: subsets } = await agent
    .get(`/api/subsets/community/${e(communityId)}`);

  return subsets.map(mapSubsetFromServer);
};

/**
 * GET /api/subsets.
 * Get the list of all subsets.
 *
 * @async
 * @returns {Array<object>} Subsets.
 */
export const getAllSubsets = async () => {
  const { body: subsets } = await agent
    .get('/api/subsets');

  return subsets.map(mapSubsetFromServer);
};
