import { noop } from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { messageShape } from 'shapes/vendor';

import { PRODUCT_STAGES } from 'config/constants';
import withStateExpanded from '../connect/withStateExpanded';

import classNames from './selectFormElement.module.scss';

const { Option } = Select;

class SelectFormElement extends React.PureComponent {
  static propTypes = {
    filters: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    ).isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.oneOfType([
        PropTypes.string,
        messageShape,
        PropTypes.node,
      ]).isRequired,
    })),
    filterKey: PropTypes.string.isRequired,
    onSetFilters: PropTypes.func,
    label: PropTypes.node.isRequired,
  };

  static defaultProps = {
    values: [],
    onSetFilters: noop,
  };

  onChange = (filterValues) => {
    // And submit.
    this.props.onSetFilters({ [this.props.filterKey]: filterValues });
  };

  /** @returns {object} JSX. */
  render() {
    const {
      values,
      filters,
      filterKey,
      label,
    } = this.props;
    return (
      <>
        {values.length > 0 && (
          <Select
            className={classNames.dropdown}
            // Limit to 1 tag and no too many characters to prevent the dropdown box from expanding
            // Could be removed later when CSS is improved
            maxTagCount={1}
            maxTagTextLength={13}
            mode="multiple"
            onChange={this.onChange}
            optionFilterProp="name"
            placeholder={label}
            size="large"
            showSearch={false}
            value={filters && filters[filterKey]}
          >
            {values.map(item => (item.id !== PRODUCT_STAGES.ARCH ? <Option key={item.id} value={item.id} name={item.name}>{item.name}</Option> : ''))}
          </Select>
        )}
      </>
    );
  }
}

export default compose(
  withStateExpanded(),
)(SelectFormElement);
