import PropTypes from 'prop-types';

export default PropTypes.shape({
  abbCommunityIds: PropTypes.arrayOf(PropTypes.string),
  abbIds: PropTypes.arrayOf(PropTypes.string),
  subsetCommunityIds: PropTypes.arrayOf(PropTypes.string),
  subsetIds: PropTypes.arrayOf(PropTypes.string),
  activityIds: PropTypes.arrayOf(PropTypes.string),
  atomicActivityIds: PropTypes.arrayOf(PropTypes.string),
  brandIds: PropTypes.arrayOf(PropTypes.string),
  businessUnitIds: PropTypes.arrayOf(PropTypes.string),
  communityIds: PropTypes.arrayOf(PropTypes.string),
  countryIds: PropTypes.arrayOf(PropTypes.string),
  domainIds: PropTypes.arrayOf(PropTypes.string),
  macroActivityIds: PropTypes.arrayOf(PropTypes.string),
  stages: PropTypes.arrayOf(PropTypes.string),
});
