import { withStateHandlers } from 'recompose';

export default (initialValue = true) => withStateHandlers(
  () => ({
    isExpanded: initialValue,
  }),
  {
    onExpand: ({ isExpanded }) => () => ({
      isExpanded: !isExpanded,
    }),
  },
);
