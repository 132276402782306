import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { productShape } from 'shapes/product';
import { DISPLAY_MODES } from 'config/constants';
import ProductGrid from 'react/business/products/grid/ProductGrid';
import ProductTable from 'react/business/products/table/ProductTable';

const ProductsPage = ({
  products,
  displayMode,
  loading,
}) => (
  displayMode !== DISPLAY_MODES.LIST ? (
    <ProductGrid
      products={products}
      loading={loading}
    />
  ) : (
    <ProductTable
      products={products}
      loading={loading}
    />
  )
);

ProductsPage.displayName = 'ProductsPage';

ProductsPage.propTypes = {
  products: PropTypes.arrayOf(productShape).isRequired,
  displayMode: PropTypes.oneOf(Object.values(DISPLAY_MODES)),
  loading: PropTypes.bool,
};

ProductsPage.defaultProps = {
  displayMode: DISPLAY_MODES.GRID,
  loading: false,
};

export default memo(ProductsPage);
