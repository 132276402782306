import agent from 'services/http';
import { mapBusinessActivityFromServer } from './business-activities.mapper';

const e = encodeURIComponent;

/**
 * GET /api/businessActivities/macroActivities.
 * Get the list of macro activities.
 *
 * @async
 * @param {object} [query] - Query parameters.
 * @param {boolean} [query.populateActivities] - Allows to get all of the activities.
 * @param {boolean} [query.populateAtomicActivities] - Allows to get all of the atomic activities.
 * @returns {Array<object>} - A list of macro activities.
 */
export const getMacroActivities = async (
  {
    populateActivities,
    populateAtomicActivities,
    withCount,
  }) => {
  const {
    body: {
      macroActivities,
      activities,
      atomicActivities,
      macroActivitiesCount,
      activitiesCount,
      atomicActivitiesCount,
    },
  } = await agent
    .get('/api/businessActivities/macroActivities')
    .query({ populateActivities, populateAtomicActivities, withCount });
  return {
    macroActivitiesCount,
    activitiesCount,
    atomicActivitiesCount,
    macroActivities: (macroActivities || []).map(ma => mapBusinessActivityFromServer(ma)),
    activities: (activities || []).map(a => mapBusinessActivityFromServer(a)),
    atomicActivities: (atomicActivities || []).map(aa => mapBusinessActivityFromServer(aa)),
  };
};

/**
 * GET /api/businessActivities/macroActivities/:macroActivityId.
 * Get a macro activity.
 *
 * @async
 * @param {string} macroActivityId - Macro activity id.
 * @returns {object} Macro activity.
 */
export const getMacroActivity = async (macroActivityId) => {
  const { macroActivity } = await agent
    .get(`/api/businessActivities/macroActivities/${e(macroActivityId)}`);
  return mapBusinessActivityFromServer(macroActivity);
};

/**
 * GET /api/businessActivities/activities.
 * Get the list of activities.
 *
 * @async
 * @param {object} [query] - Query parameters.
 * @param {Array<string>} [query.activityIds] - Activity IDs.
 * @param {Array<string>} [query.macroActivityIds] - Get activities
 * for specific macro activities by ID.
 * @returns {Array<object>} - A list of activities.
 */
export const getActivities = async ({ activityIds, macroActivityIds }) => {
  const { body: { activities, count } } = await agent
    .get('/api/businessActivities/activities')
    .query({ activityIds, macroActivityIds });
  return {
    count,
    activities: activities.map(activity => mapBusinessActivityFromServer(activity)),
  };
};

/**
 * GET /api/businessActivities/activities/:activityId.
 * Get an activity.
 *
 * @async
 * @param {string} activityId - Activity id.
 * @returns {object} Activity.
 */
export const getActivity = async (activityId) => {
  const { activity } = await agent
    .get(`/api/businessActivities/activities/${e(activityId)}`);
  return mapBusinessActivityFromServer(activity);
};

/**
 * GET /api/businessActivities/atomicActivities.
 * Get the list of atomic activities.
 *
 * @async
 * @param {object} [query] - Query parameters.
 * @param {Array<string>} [query.atomicActivityIds] - Get specific atomic activities by ID.
 * @param {Array<string>} [query.activityIds] - Get atomic activities for specific activities by ID.
 * @returns {Array<object>} - A list of atomic activities.
 */
export const getAtomicActivities = async ({ atomicActivityIds, activityIds }) => {
  const { body: { atomicActivities, count } } = await agent
    .get('/api/businessActivities/atomicActivities')
    .send({ atomicActivityIds, activityIds });
  return {
    count,
    atomicActivities: atomicActivities.map(activity => mapBusinessActivityFromServer(activity)),
  };
};

/**
 * GET /api/businessActivities/atomicActivities/:atomicActivityId.
 * Get an atomic activity.
 *
 * @async
 * @param {string} atomicActivityId - Atomic activity id.
 * @returns {object} Atomic activity.
 */
export const getAtomicActivity = async (atomicActivityId) => {
  const { atomicActivity } = await agent
    .get(`/api/businessActivities/atomicActivities/${e(atomicActivityId)}`);
  return mapBusinessActivityFromServer(atomicActivity);
};
