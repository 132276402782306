import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { selectSubsetsMap } from 'redux/subsets/selectors';
import { getAllSubsets } from 'redux/subsets/actions';

export default compose(
  connect(
    state => ({
      subsets: selectSubsetsMap(state),
    }),
    {
      getAllSubsets,
    },
  ),

  lifecycle({
    /**
     * Load all Subsets.
     *
     * @async
     */
    async componentDidMount() {
      await this.props.getAllSubsets();
    },
  }),
);
