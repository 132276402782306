import React from 'react';

import { FormattedMessage } from 'react-intl';
import messages from '../../facets.messages';
import { FILTERS } from '../../facets.constants';
import SelectFormElement from '../../formElements/SelectFormElement';

export default props => (
  <SelectFormElement
    {...props}
    label={<FormattedMessage {...messages.FACET_BUSINESS_UNITS_USERS} />}
    filterKey={FILTERS.BUSINESS_UNIT_IDS}
  />
);
