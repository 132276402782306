import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';

import { FormattedMessage } from 'react-intl';

import LinearProgress from 'react/generic/progress/LinearProgress';
import Button from 'react/generic/button/Button';

import globalMessages from 'config/global.messages';
import classNames from './loadMore.module.scss';

const enhancer = compose(
  memo,
);

const LoadMore = ({
  currentCount,
  totalCount,
  renderCount,
  onClickLoadMore,
  className,
  ...props
}) => (
  <div
    {...props}
    className={cn(className, classNames.container)}
  >
    { renderCount
      && (
        <div className={classNames.count}>{renderCount(currentCount, totalCount)}</div>
      ) }

    <LinearProgress progress={totalCount !== 0 ? currentCount * 100 / totalCount : 0} />

    { currentCount !== totalCount
      && (
        <div className={classNames.buttonContainer}>
          <Button
            outline
            overrideClassName={classNames.button}
            overrideClassNames={classNames}
            onClick={onClickLoadMore}
          >
            <FormattedMessage {...globalMessages.LOAD_MORE} />
          </Button>
        </div>
      ) }
  </div>
);

LoadMore.displayName = 'LoadMore';

LoadMore.propTypes = {
  currentCount: PropTypes.number,
  totalCount: PropTypes.number,
  renderCount: PropTypes.func,
  onClickLoadMore: PropTypes.func,
  className: PropTypes.string,
};

LoadMore.defaultProps = {
  currentCount: 0,
  totalCount: 0,
  renderCount: null,
  onClickLoadMore: noop,
  className: null,
};

export default enhancer(LoadMore);
