import { noop } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PAGE_SIZE } from 'config/constants';

export default options => (WrappedComponent) => {
  const optionsWithDefaults = {
    itemsPropName: 'items',
    pageSize: PAGE_SIZE,
    ...options,
  };

  return class withLoadMore extends Component {
    static propTypes = {
      totalCount: PropTypes.number,
      onLoadPage: PropTypes.func,
    };

    static defaultProps = {
      totalCount: 0,
      onLoadPage: noop,
    };

    /**
     * Get the items from the props.
     *
     * @returns {Array<object>} The items.
     */
    getItems() {
      return this.props[optionsWithDefaults.itemsPropName];
    }

    /**
     * Get the count of items from the props.
     *
     * @returns {Array<object>} The items.
     */
    getItemsCount() {
      return this.getItems()?.length || 0;
    }

    /**
     * Get the current page given the number of items.
     *
     * @returns {number} Current page.
     */
    getCurrentPage() {
      return Math.floor(this.getItemsCount() / optionsWithDefaults.pageSize);
    }

    onLoadMore = () => this.props.onLoadPage(
      this.getCurrentPage(),
      { currentCount: this.getItemsCount() },
    );

    /** @returns {object} JSX. */
    render() {
      const {
        onLoadPage,
        ...props
      } = this.props;

      return (
        <WrappedComponent
          {...props}
          currentCount={this.getItemsCount()}
          totalCount={this.props.totalCount}
          onClickLoadMore={this.onLoadMore}
        />
      );
    }
  };
};
