import PropTypes from 'prop-types';

export const domainShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  shortName: PropTypes.string,
  community: PropTypes.shape({
    id: PropTypes.string,
  }),
  description: PropTypes.string,
  productCount: PropTypes.number,
  businessDomainLeaderId: PropTypes.string,
  digitalDomainLeaderId: PropTypes.string,
});

export const communityShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  shortName: PropTypes.string,
  businessCommunityLeaderId: PropTypes.string,
  digitalCommunityLeaderId: PropTypes.string,
  domains: PropTypes.arrayOf(domainShape),
  communityArchitect: PropTypes.string,
});
