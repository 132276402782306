import { FormattedMessage } from 'react-intl';
import React from 'react';
import SelectFormElement from '../../formElements/SelectFormElement';
import messages from '../../facets.messages';
import { FILTERS } from '../../facets.constants';

export default props => (
  <SelectFormElement
    {...props}
    label={<FormattedMessage {...messages.FACET_MAINTAINED_BY_PLATFORM} />}
    filterKey={FILTERS.IS_MAINTAINED_BY_PLATFORM}
  />
);
