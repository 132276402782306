import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import memoize from 'memoize-one';

import classNames from './linearProgress.module.scss';

export default class LinearProgress extends PureComponent {
  static propTypes = {
    /** Progress (between 0 and 100). */
    progress: PropTypes.number,
    /** Root element class name. */
    className: PropTypes.string,
    /** Content style. */
    contentStyle: PropTypes.shape({}),
    /** Content class name. */
    contentClassName: PropTypes.string,
  };

  static defaultProps = {
    progress: 0,
    className: null,
    contentStyle: null,
    contentClassName: null,
  };

  /**
   * Make sure that progress is between 0 and 100.
   *
   * @param {number} progress - Progress.
   * @returns {number} Progress between 0 and 100.
   */
  static sanitizeProgress(progress) {
    return Math.min(100, Math.max(0, progress));
  }

  makeStyle = memoize((style, progress) => ({
    ...style,
    width: `${LinearProgress.sanitizeProgress(progress)}%`,
  }))

  /** @returns {object} JSX. */
  render() {
    const {
      progress,
      className,
      contentStyle,
      contentClassName,
      ...props
    } = this.props;

    return (
      <div
        {...props}
        className={cn(
          className,
          classNames.container,
        )}
      >
        <div
          className={cn(
            contentClassName,
            classNames.content,
          )}
          style={this.makeStyle(contentStyle, progress)}
        />
      </div>
    );
  }
}
