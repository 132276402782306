export const FILTERS = {
  COMMUNITY_IDS: 'communityIds',
  DOMAIN_IDS: 'domainIds',
  BUSINESS_UNIT_IDS: 'businessUnitIds',
  BUSINESS_UNIT_OWNER_IDS: 'businessUnitOwnerIds',
  COUNTRY_IDS: 'countryIds',
  BRAND_IDS: 'brandIds',
  STAGES_ID: 'stages',
  KINDS_ID: 'kinds',
  TITLE_ID: 'title',
  SORT: 'sort',
  MACRO_ACTIVITY_IDS: 'macroActivityIds',
  ACTIVITY_IDS: 'activityIds',
  ATOMIC_ACTIVITY_IDS: 'atomicActivityIds',
  ARCHITECTURE_BUILDING_BLOCK_IDS: 'abbIds',
  SUBSET_IDS: 'subsetIds',
  ABB_COMMUNITY_IDS: 'abbCommunityIds',
  SUBSET_COMMUNITY_IDS: 'subsetCommunityIds',
  IS_MAINTAINED_BY_PLATFORM: 'isMaintainedByPlatform',
};

export const FILTERS_DISPLAY_TYPES = {
  DEFAULT: 'default',
  FILTERS_TOP: 'filters-top',
};
