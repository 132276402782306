import React from 'react';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

export const MATURITY_BADGES = {
  security: {
    assetOn: <Asset name={ICON_MAP.security_on} />,
    assetOff: <Asset name={ICON_MAP.security_off} />,
  },

  architecture: {
    assetOn: <Asset name={ICON_MAP.architecture_on} />,
    assetOff: <Asset name={ICON_MAP.architecture_off} />,
  },

  innersource: {
    assetOn: <Asset name={ICON_MAP.innersource_on} />,
    assetOff: <Asset name={ICON_MAP.innersource_off} />,
  },
};
