import { groupBy } from 'lodash';

export const makeActivitiesTree = (subActivitiesMap, groupByProperty) => {
  const groupedActivities = groupBy(Object.values(subActivitiesMap), groupByProperty);
  Object.keys(groupedActivities).forEach((activityId) => {
    groupedActivities[activityId] = groupedActivities[activityId]
      .map(({ id }) => id);
  });
  return groupedActivities;
};

export const makeActivitiesObjectTree = (subActivitiesMap, groupByProperty) => groupBy(
  Object.values(subActivitiesMap),
  groupByProperty,
);
