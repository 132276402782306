import React from 'react';
import { compose, lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { TreeSelect } from 'antd';
import { FormattedMessage } from 'react-intl';
import memoize from 'memoize-one';
import connect from 'react/hoc/connectProxy';

import { getCommunities } from 'redux/communities/actions';
import { selectCommunitiesMap } from 'redux/communities/selectors';
import { subsetsShape } from 'shapes/subsets';
import { communityShape } from 'shapes/community';
import withAllSubsets from 'react/business/subsets/card/connect/withAllSubsets.connect';

import { FILTERS } from '../../facets.constants';
import messages from '../../facets.messages';

import classNames from './subsets.module.scss';

const { TreeNode } = TreeSelect;

const enhancer = compose(
  withAllSubsets,

  connect(
    state => ({
      communities: selectCommunitiesMap(state),
    }),
    { getCommunities },
  ),

  lifecycle({
    /**
     * Load communities list on mount.
     */
    async componentDidMount() {
      await this.props.getCommunities({
        populateDomains: true,
        countProducts: false,
      });
    },
  }),
);

const renderTreeNodes = (
  subsets,
  communities,
) => {
  const tree = [];
  Object.entries(subsets).forEach(([key, value]) => {
    tree.push(
      <TreeNode
        title={communities[key]?.name}
        key={key}
        value={key}
      >
        {value.map(subset => (
          <TreeNode
            title={subset.name}
            key={subset.id}
            value={subset.id}
          />
        ))}
      </TreeNode>,
    );
  });
  return tree;
};

class Subsets extends React.PureComponent {
  onChange = (values) => {
    const subsets = [];
    Object.entries(this.props.subsets).forEach(([, value]) => {
      subsets.push(...value);
    });

    const checkedValues = values.reduce((map, current) => {
      const foundCommunityId = subsets.find(subset => subset.id === current).communityId;
      if (foundCommunityId) {
        map[foundCommunityId] = [
          ...(map[foundCommunityId] || []),
          current,
        ];
      }
      return map;
    }, {});

    const checkedSubsetIds = [];
    const checkedCommunityIds = [];

    Object.entries(checkedValues).forEach(([key, value]) => {
      if (value.length === this.props.subsets[key].length) {
        checkedCommunityIds.push(key);
      } else {
        checkedSubsetIds.push(...value);
      }
    });

    // Set the ids into filters.
    this.props.onSetFilters({
      [FILTERS.SUBSET_IDS]: checkedSubsetIds,
      [FILTERS.SUBSET_COMMUNITY_IDS]: checkedCommunityIds,
    });
  };

  getCheckedKeys = memoize((checkedSubsetIds, checkedCommunityIds) => [
    ...checkedSubsetIds,
    ...checkedCommunityIds,
  ]);

  renderTreeNodesMemoized = memoize(renderTreeNodes);

  /**
   * @returns {object} JSX.
   */
  render() {
    const {
      filters,
      subsets,
      communities,
    } = this.props;

    return (
      <TreeSelect
        className={classNames.dropdown}
        searchPlaceholder={<FormattedMessage {...messages.FACET_SUBSETS} />}
        size="large"
        maxTagCount={1}
        maxTagTextLength={13}
        treeCheckable
        onChange={this.onChange}
        value={this.getCheckedKeys(
          filters[FILTERS.SUBSET_IDS] || [],
          filters[FILTERS.SUBSET_COMMUNITY_IDS] || [],
        )}
        dropdownStyle={{ maxHeight: '20rem' }}
      >
        {this.renderTreeNodesMemoized(subsets, communities)}
      </TreeSelect>
    );
  }
}

Subsets.displayName = 'Subsets';

Subsets.propTypes = {
  subsets: PropTypes.objectOf(PropTypes.arrayOf(subsetsShape)),
  communities: PropTypes.objectOf(communityShape),
  onSetFilters: PropTypes.func,
  onChange: PropTypes.func,
  filters: PropTypes.shape({
    [FILTERS.SUBSET_IDS]: PropTypes.arrayOf(PropTypes.string),
    [FILTERS.SUBSET_COMMUNITY_IDS]: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  getCheckedKeys: PropTypes.func,
};

Subsets.defaultProps = {
  subsets: {},
  communities: {},
  onSetFilters: noop,
  onChange: noop,
  getCheckedKeys: noop,
};

export default enhancer(Subsets);
