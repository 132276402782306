import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import cn from 'classnames';
import { noop } from 'lodash';
import { Table, Tooltip } from 'antd';
import { injectIntl, intlShape } from 'react-intl';
import memoize from 'memoize-one';

import { productShape } from 'shapes/product';
import { localeSorterInObject, numericSorterInObject } from 'services/utils';
import { MAX_MATURITY } from 'config/constants';
import { MATURITY_BADGES } from 'react/business/products/badges/maturityBadge/badges';
import RateLabel from 'react/generic/rate/RateLabel';

import badgesMessages from 'react/business/products/badges/maturityBadge/badges.messages';
import messages from './product-table.messages';
import classNames from './product-table.module.scss';

const enhancer = compose(
  injectIntl,
  memo,
);

class ProductTable extends React.PureComponent {
  static displayName = 'ProductTable';

  static propTypes = {
    products: PropTypes.arrayOf(productShape).isRequired,
    loading: PropTypes.bool,
    onClickProduct: PropTypes.func,
    className: PropTypes.string,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    className: null,
    loading: false,
    onClickProduct: noop,
  };

  getColumns = memoize(intl => [{
    title: intl.formatMessage(messages.NAME),
    key: 'name',
    sorter: localeSorterInObject('name'),
    className: classNames.left,
    render: this.renderTitle,
  }, {
    title: intl.formatMessage(messages.VERSION),
    dataIndex: 'version',
    key: 'version',
  }, {
    title: intl.formatMessage(messages.AVERAGE),
    dataIndex: 'mark',
    key: 'mark',
    sorter: numericSorterInObject('mark'),
    render: this.renderAverage,
  }, {
    title: intl.formatMessage(messages.FEEDBACKS),
    dataIndex: 'reviewCount',
    key: 'reviewCount',
    sorter: numericSorterInObject('reviewCount'),
  }, {
    title: intl.formatMessage(messages.USERS),
    dataIndex: 'usersCount',
    key: 'usersCount',
    sorter: numericSorterInObject('usersCount'),
  }, {
    title: intl.formatMessage(messages.BU),
    dataIndex: 'buCount',
    key: 'buCount',
    sorter: numericSorterInObject('buCount'),
  }, {
    title: intl.formatMessage(messages.FOLLOWERS),
    dataIndex: 'followersCount',
    key: 'followersCount',
    sorter: numericSorterInObject('followersCount'),
  }, {
    title: intl.formatMessage(badgesMessages.SECURITY),
    dataIndex: 'maturity.security',
    key: 'security',
    sorter: numericSorterInObject('maturity.security'),
    render: this.renderBadge('security'),
  }, {
    title: intl.formatMessage(badgesMessages.ARCHITECTURE),
    dataIndex: 'maturity.architecture',
    key: 'architecture',
    sorter: numericSorterInObject('maturity.architecture'),
    render: this.renderBadge('architecture'),
  }, {
    title: intl.formatMessage(badgesMessages.INNERSOURCE),
    dataIndex: 'maturity.innersource',
    key: 'innersource',
    sorter: numericSorterInObject('maturity.innersource'),
    render: this.renderBadge('innersource'),
  }]);

  onClickRow = row => () => {
    this.props.onClickProduct(row.id);
  };

  getRowKey = row => row.id;

  renderAverage = average => (
    <RateLabel mark={average} className={cn(classNames.average)} />
  );

  renderBadge = badgeKey => value => (
    <Tooltip
      placement="top"
      title={`${value} / ${MAX_MATURITY}`}
    >
      <div className={classNames.iconContainer}>
        {/* TODO: use assets given the mark. */}
        {MATURITY_BADGES[badgeKey].assetOn}
      </div>
    </Tooltip>
  );

  renderTitle = row => (
    <button
      type="button"
      className={cn('unstyled', classNames.linkToProduct)}
      onClick={this.onClickRow(row)}
    >
      {row.name}
    </button>
  );

  /** @returns {object} JSX. */
  render() {
    const {
      products,
      loading,
      className,
      intl,
    } = this.props;

    const columns = this.getColumns(intl);

    return (
      <Table
        columns={columns}
        dataSource={products}
        loading={loading}
        className={cn(className, classNames.table)}
        rowClassName={classNames.row}
        rowKey={this.getRowKey}
        pagination={false}
      />
    );
  }
}

export default enhancer(ProductTable);
