import connect from 'react/hoc/connectProxy';
import { compose, lifecycle } from 'recompose';
import {
  selectActivitiesMap,
  selectAtomicActivitiesMap,
  selectBusinessActivitiesTree,
  selectMacroActivitiesMap,
} from 'redux/businessActivities/selectors';
import { getMacroActivities as getMacroActivitiesAction } from 'redux/businessActivities/actions';

export default compose(
  connect(state => ({
    macroActivitiesMap: selectMacroActivitiesMap(state),
    activitiesMap: selectActivitiesMap(state),
    atomicActivitiesMap: selectAtomicActivitiesMap(state),
    tree: selectBusinessActivitiesTree(state),
  }), {
    getMacroActivities: () => getMacroActivitiesAction({
      populateActivities: true,
      populateAtomicActivities: true,
      withCount: false,
    }),
  }),

  lifecycle({
    /** Load macro activities on mount. */
    async componentDidMount() {
      await this.props.getMacroActivities();
    },
  }),
);
