import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { selectArchitectureBuildingBlocksMap } from 'redux/architectureBuildingBlocks/selectors';
import { getAllArchitectureBuildingBlocks } from 'redux/architectureBuildingBlocks/actions';

export default compose(
  connect(
    state => ({
      abbs: selectArchitectureBuildingBlocksMap(state),
    }),
    {
      getAllArchitectureBuildingBlocks,
    },
  ),

  lifecycle({
    /**
     * Load all ABBs.
     *
     * @async
     */
    async componentDidMount() {
      await this.props.getAllArchitectureBuildingBlocks();
    },
  }),
);
