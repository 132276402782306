import connect from 'react/hoc/connectProxy';
import { compose, lifecycle } from 'recompose';
import { selectBusinessUnitsListSortedByName } from 'redux/businessUnits/selectors';
import { getBusinessUnits } from 'redux/businessUnits/actions';

export default compose(
  connect(
    state => ({
      values: selectBusinessUnitsListSortedByName(state),
    }),
    {
      getBusinessUnits,
    },
  ),

  lifecycle({
    /** Load business units on mount. */
    componentDidMount() {
      this.props.getBusinessUnits();
    },
  }),
);
