import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { FormattedMessage } from 'react-intl';
import ControlButton from '../button/ControlButton';

import messages from './advancedFiltersButton.messages';

const ResetButton = ({
  shouldShowMoreFilters,
  onHideMoreFilters,
  onShowMoreFilters,
}) => (
  <ControlButton
    outline
    onClick={shouldShowMoreFilters ? onHideMoreFilters : onShowMoreFilters}
  >
    <FormattedMessage
      {...(shouldShowMoreFilters ?
        messages.HIDE_MORE_FILTERS
        : messages.SHOW_MORE_FILTERS
      )}
    />
  </ControlButton>
);

ResetButton.displayName = 'ResetButton';

ResetButton.propTypes = {
  shouldShowMoreFilters: PropTypes.bool,
  onHideMoreFilters: PropTypes.func,
  onShowMoreFilters: PropTypes.func,
};

ResetButton.defaultProps = {
  shouldShowMoreFilters: false,
  onHideMoreFilters: noop,
  onShowMoreFilters: noop,
};

export default memo(ResetButton);
