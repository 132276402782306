import PropTypes from 'prop-types';

export const macroActivityShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
});

export const activityShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  macroActivityId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  displayOrder: PropTypes.number,
});

export const atomicActivityShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  activityId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  displayOrder: PropTypes.number,
});

export const treeElementShape = PropTypes.shape({
  id: PropTypes.string,
  // Array of treeElementShape but i'd rather avoid recursion.
  children: PropTypes.array,
});
