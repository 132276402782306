import agent from 'services/http';
import { mapArchitectureBuildingBlockFromServer } from './architecture-building-blocks.mapper';

const e = encodeURIComponent;

/**
 * GET /api/abb/community/{communityId}/abb.
 * Get the list of ABB for a community.
 *
 * @async
 * @param {*} communityId - Id of the community.
 * @returns {Array<object>} ABBs.
 */
export const getArchitectureBuildingBlocksByCommunityId = async (communityId) => {
  const { body: abbs } = await agent
    .get(`/api/abb/community/${e(communityId)}/abb`);

  return abbs.map(mapArchitectureBuildingBlockFromServer);
};

/**
 * GET /api/abb.
 * Get the list of all ABB.
 *
 * @async
 * @returns {Array<object>} ABBs.
 */
export const getAllArchitectureBuildingBlocks = async () => {
  const { body: abbs } = await agent
    .get('/api/abb');

  return abbs.map(mapArchitectureBuildingBlockFromServer);
};
