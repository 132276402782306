import { noop } from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import memoize from 'memoize-one';
import { TreeSelect } from 'antd';

import { communityShape } from 'shapes/community';

import messages from '../../facets.messages';
import { FILTERS } from '../../facets.constants';
import withStateExpanded from '../../connect/withStateExpanded';

import classNames from './communities.module.scss';

const { TreeNode } = TreeSelect;

// Given the structure, generate a renderable tree.
const renderTreeNodes = items => (items || []).map((item) => {
  if (item.domains) {
    return (
      <TreeNode title={item.name} key={item.id} value={item.id}>
        {renderTreeNodes(item.domains)}
      </TreeNode>
    );
  }
  return <TreeNode title={item.name} key={item.id} value={item.id} />;
});

class Communities extends React.PureComponent {
  static propTypes = {
    filters: PropTypes.shape({
      [FILTERS.DOMAIN_IDS]: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    domainsCount: PropTypes.number.isRequired,
    communities: PropTypes.arrayOf(communityShape),
    onSetFilters: PropTypes.func,
  };

  static defaultProps = {
    communities: [],
    onSetFilters: noop,
  };

  // Memoize the tree node.
  renderTreeNodesMemoized = memoize(renderTreeNodes);

  getCheckedKeys = memoize((communityIds, domainIds) => [
    ...communityIds,
    ...domainIds,
  ]);

  onChange = (values) => {
    const {
      checkedCommunityIds,
      checkedDomainIds,
    } = this.props.communities.reduce(
      (result, community) => {
        const isCommunityChecked = values.includes(community.id);
        const checkedCommunityDomains = community.domains.filter(({ id }) => values.includes(id));
        const areAllItsDomainsChecked = checkedCommunityDomains.length === community.domains.length;

        if (isCommunityChecked || areAllItsDomainsChecked) {
          result.checkedCommunityIds.push(community.id);
        } else {
          result.checkedDomainIds.push(...checkedCommunityDomains.map(d => d.id));
        }

        return result;
      },
      {
        checkedCommunityIds: [],
        checkedDomainIds: [],
      },
    );

    // And submit.
    this.props.onSetFilters({
      [FILTERS.COMMUNITY_IDS]: checkedCommunityIds,
      [FILTERS.DOMAIN_IDS]: checkedDomainIds,
    });
  };

  /** @returns {object} JSX. */
  render() {
    const {
      communities,
      domainsCount,
      filters,
    } = this.props;
    return (
      <>
        {communities.length > 0 && communities.length === domainsCount && (
          <TreeSelect
            className={classNames.dropdown}
            // Limit to 1 tag and no too many characters to prevent the dropdown box from expanding
            // Could be removed later when CSS is improved
            maxTagCount={1}
            maxTagTextLength={13}
            onChange={this.onChange}
            searchPlaceholder={<FormattedMessage {...messages.FACET_COMMUNITIES} />}
            size="large"
            treeCheckable
            value={this.getCheckedKeys(
              filters[FILTERS.COMMUNITY_IDS],
              filters[FILTERS.DOMAIN_IDS],
            )}
          >
            {this.renderTreeNodesMemoized(communities)}
          </TreeSelect>
        )}
      </>
    );
  }
}

export default compose(
  withStateExpanded(),
)(Communities);
