import React, { memo } from 'react';

import Button from 'react/generic/button/Button';

import classNames from './controlButton.module.scss';

const ControlButton = ({ ...props }) => (
  <Button
    {...props}
    overrideClassName={classNames.control}
    overrideClassNames={classNames}
  />
);

ControlButton.displayName = 'ControlButton';

export default memo(ControlButton);
