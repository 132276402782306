import { PRODUCT_SORTING } from 'config/constants';
import productMessages from 'react/business/products/product.messages';

const sortingOptions = [
  {
    id: PRODUCT_SORTING.NAME_ASC,
    name: productMessages[PRODUCT_SORTING.NAME_ASC],
  },
  {
    id: PRODUCT_SORTING.CREATED_DATE_DESC,
    name: productMessages[PRODUCT_SORTING.CREATED_DATE_DESC],
  },
  {
    id: PRODUCT_SORTING.UPDATED_DATE_DESC,
    name: productMessages[PRODUCT_SORTING.UPDATED_DATE_DESC],
  },
];

export default sortingOptions;
