import { createSelector } from 'reselect';

import { numericSorterInObject } from 'services/utils';
import { makeActivitiesTree, makeActivitiesObjectTree } from 'services/businessActivities/utils';
import { buildTreeFromAtomicActivities } from 'services/businessActivities/functions';

const sorterByOrder = numericSorterInObject('displayOrder');

export const selectMacroActivitiesIsLoading = state => !!state
  .businessActivities.isLoading.macroActivities;

export const selectActivitiesIsLoading = state => !!state
  .businessActivities.isLoading.activities;

export const selectAtomicActivitiesIsLoading = state => !!state
  .businessActivities.isLoading.atomicActivities;

export const selectIsLoadingAny = createSelector(
  [
    selectMacroActivitiesIsLoading,
    selectActivitiesIsLoading,
    selectAtomicActivitiesIsLoading,
  ],
  (isLoadingMacroActivities, isLoadingActivities, isLoadingAtomicActivities) => (
    isLoadingMacroActivities || isLoadingActivities || isLoadingAtomicActivities
  ),
);

export const selectMacroActivitiesMap = state => state.businessActivities.map.macroActivities;

export const selectActivitiesMap = state => state.businessActivities.map.activities;

export const selectAtomicActivitiesMap = state => state.businessActivities.map.atomicActivities;

export const selectMacroActivityById = (
  state,
  macroActivityId,
) => selectMacroActivitiesMap(state)[macroActivityId];

export const selectAtomicActivityById = (
  state,
  atomicActivityId,
) => selectAtomicActivitiesMap(state)[atomicActivityId];

export const selectMacroActivitiesTree = createSelector(
  selectActivitiesMap,
  activitiesMap => makeActivitiesTree(activitiesMap, 'macroActivityId'),
);

export const selectActivitiesTree = createSelector(
  selectAtomicActivitiesMap,
  atomicActivitiesMap => makeActivitiesTree(atomicActivitiesMap, 'activityId'),
);

export const selectActivitiesByMacroActivityId = (
  state,
  macroActivityId,
) => selectMacroActivitiesTree(state)[macroActivityId];

export const selectAtomicActivitiesByActivityId = (
  state,
  activityId,
) => selectActivitiesTree(state)[activityId];

export const selectBusinessActivitiesTree = createSelector(
  [
    selectActivitiesMap,
    selectAtomicActivitiesMap,
  ],
  (activitiesMap, atomicActivitiesMap) => buildTreeFromAtomicActivities(
    Object.keys(atomicActivitiesMap),
    {
      atomicActivitiesMap,
      activitiesMap,
    },
  ),
);

export const selectMacroActivitiesObjectTree = createSelector(
  selectActivitiesMap,
  activitiesMap => makeActivitiesObjectTree(activitiesMap, 'macroActivityId'),
);

export const selectActivitiesObjectTree = createSelector(
  selectAtomicActivitiesMap,
  atomicActivitiesMap => makeActivitiesObjectTree(atomicActivitiesMap, 'activityId'),
);

export const selectActivitiesListByMacroActivityId = (
  state,
  macroActivityId,
) => Object.values(selectMacroActivitiesObjectTree(state)[macroActivityId] || {})
  .sort(sorterByOrder);

export const selectAtomicActivitiesListByActivityId = (
  state,
  activityId,
) => Object.values(selectActivitiesObjectTree(state)[activityId] || {})
  .sort(sorterByOrder);
