import React, { memo } from 'react';
import connect from 'react/hoc/connectProxy';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import { getProducts } from 'redux/products/actions';
import {
  selectAreProductsLoading,
  selectDisplayModeWithResponsive,
  selectProductsTotalCount,
  selectProductsList,
} from 'redux/products/selectors';
import { selectUserSearchCachedFilters } from 'redux/users/selectors';

import { NAVIGATION_SHORTCUTS_MESSAGES } from 'config/constants.messages';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import withLoadMore from 'react/generic/pagination/loadMore/withLoadMore';

import PaginatedProducts from 'react/business/products/pagination/PaginatedProducts.container';
import ProductsPage from 'react/pages/product/productsPage/ProductsPage';
import ProductsLoadMore from 'react/business/products/pagination/loadMore/LoadMore.presentation';

import { defaultFilters } from 'react/business/facets/connect/withFiltersControllerInState';
import FacetsContainerProducts from 'react/business/facets/FacetsContainerProducts';
import FiltersWithContentLayout from 'react/business/facets/layout/FiltersWithContentLayout';
import TitleHeader from 'react/business/layout/titleHeader/TitleHeader';
import ProductBanner from 'react/business/products/banner/ProductBanner';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import { FILTERS_DISPLAY_TYPES } from 'react/business/facets/facets.constants';

import PropTypes from 'prop-types';
import { formatRoute } from 'react-router-named-routes';
import ROUTES from 'react/routes';
import messages from './product-list.messages';
import classNames from './productList.module.scss';
import filtersShape from '../../../shapes/filters';
import withFiltersControllerInQueryString from '../../business/facets/connect/withFiltersControllerInQueryString';

const ConnectedFacets = compose(
  memo,
  withRouter,
  connect(
    state => ({
      cachedSearchFiters: selectUserSearchCachedFilters(state),
    }),
    null,
  ),
  withFiltersControllerInQueryString({ baseRoute: formatRoute(ROUTES.PRODUCTS) }),
)(FacetsContainerProducts);

const ConnectedProductsPage = compose(

  withRouter,
  connect(
    (state, props) => ({
      cachedSearchFiters: selectUserSearchCachedFilters(state),
      products: selectProductsList(state),
      totalCount: selectProductsTotalCount(state),
      loading: selectAreProductsLoading(state),
      displayMode: selectDisplayModeWithResponsive(state),
      externalId: props.match.params.externalId,
    }),
    { getProducts },
  ),
  PaginatedProducts(),
  withLoadMore({ itemsPropName: 'products' }),
  ProductsLoadMore,
)(ProductsPage);

const ProductList = () => (
  <ApplicationLayout
    mainClassName={classNames.main}
  >
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>
    <ProductBanner />
    <TitleHeader>
      <FormattedMessage {...NAVIGATION_SHORTCUTS_MESSAGES.ALL_PRODUCTS} />
    </TitleHeader>
    <FiltersWithContentLayout
      filtersComponent={ConnectedFacets}
      content={<ConnectedProductsPage />}
      type={FILTERS_DISPLAY_TYPES.FILTERS_TOP}
    />
  </ApplicationLayout>
);

ProductList.propTypes = {
  externalId: PropTypes.string,
  cachedSearchFiters: filtersShape,
};

ProductList.defaultProps = {
  externalId: '',
  cachedSearchFiters: defaultFilters,
};
ProductList.displayName = 'ProductList';

export default ProductList;
