/**
 * Build the tree of business activities starting from the bottom.
 *
 * @param {string[]} atomicActivities - Atomic activities to build from.
 * @param {object} maps - Maps from store.
 * @param {object} maps.activitiesMap - Activities from store.
 * @param {object} maps.atomicActivitiesMap - AtomicActivities from store.
 * @returns {object[]} - Tree.
 */
export const buildTreeFromAtomicActivities = (atomicActivities, {
  activitiesMap,
  atomicActivitiesMap,
}) => {
  const activities = atomicActivities.reduce((acc, atomicActivityId) => {
    const currentAtomicActivity = atomicActivitiesMap[atomicActivityId];

    if (currentAtomicActivity) {
      const { activityId } = currentAtomicActivity;

      if (!acc[activityId]) {
        acc[activityId] = {
          id: activityId,
          children: [],
        };
      }

      acc[activityId].children.push({ id: atomicActivityId });
    }
    return acc;
  }, {});

  const macroActivities = Object.values(activities).reduce((acc, activity) => {
    const currentActivity = activitiesMap[activity.id];

    if (currentActivity) {
      const { macroActivityId } = currentActivity;

      if (!acc[macroActivityId]) {
        acc[macroActivityId] = {
          id: macroActivityId,
          children: [],
        };
      }

      acc[macroActivityId].children.push(activity);
    }
    return acc;
  }, {});

  return Object.values(macroActivities);
};
