import React, { memo } from 'react';
import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import { Icon } from 'antd';

import { selectDisplayBannerFollowProduct, selectLastFollowProductName } from 'redux/products/selectors';
import { setDisplayBannerFollowProduct as setDisplayBannerFollowProductAction } from 'redux/products/actions';

import messages from './product-banner.messages';
import classNames from './product-banner.module.scss';

const enhancer = compose(
  connect(
    state => ({
      displayBanner: selectDisplayBannerFollowProduct(state),
      productName: selectLastFollowProductName(state),
    }),
    {
      setDisplayBannerFollowProduct: setDisplayBannerFollowProductAction,
    },
  ),

  memo,
);

const onClose = (setDisplayBannerFollowProduct) => {
  setDisplayBannerFollowProduct(false, '');
};

// eslint-disable-next-line react/prop-types
const ProductBanner = ({ displayBanner, setDisplayBannerFollowProduct, productName }) => (
  displayBanner && (
    <div className={classNames.productBanner}>
      <div className={classNames.content}>
        <FormattedMessage {...messages.CONTENT} values={{ productName }} />
      </div>
      <button
        type="button"
        className={cn('unstyled', 'with-pointer', classNames.closeButton)}
        onClick={() => onClose(setDisplayBannerFollowProduct)}
      >
        <Icon type="close-circle" />
      </button>
    </div>
  )
);

ProductBanner.displayName = 'ProductBanner';

export default enhancer(ProductBanner);
