import { defineMessages } from 'react-intl';

export default defineMessages({
  NAME: { id: 'product.table.name', defaultMessage: 'Name' },
  VERSION: { id: 'product.table.version', defaultMessage: 'Version' },
  AVERAGE: { id: 'product.table.average', defaultMessage: 'Average' },
  FEEDBACKS: { id: 'product.table.feedbacks', defaultMessage: 'Feedbacks' },
  USERS: { id: 'product.table.users', defaultMessage: 'Users' },
  BU: { id: 'product.table.bu', defaultMessage: 'BU' },
  FOLLOWERS: { id: 'product.table.followers', defaultMessage: 'Followers' },
});
