import React from 'react';
import { noop } from 'lodash';
import { compose, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';
import messages from 'react/business/facets/facets.messages';
import { FILTERS } from 'react/business/facets/facets.constants';

import withStateExpanded from 'react/business/facets/connect/withStateExpanded';

import classNames from './productSort.module.scss';

const { Option } = Select;

const enhancer = compose(
  withStateExpanded(),

  withHandlers({
    onChange: ({ onSetFilters }) => filterValues => onSetFilters({ [FILTERS.SORT]: filterValues }),
  }),
);

const ProductSort = ({
  filters,
  sortingOptions,
  onChange,
}) => (
  <>
    {sortingOptions.length > 0 && (
      <Select
        className={classNames.dropdown}
        onChange={onChange}
        optionLabelProp="label"
        placeholder={<FormattedMessage {...messages.SORTING_OPTIONS} />}
        size="large"
        showArrow={false}
        showSearch={false}
        value={filters && filters[FILTERS.SORT]}
      >
        {sortingOptions.map(item => (
          <Option
            key={item.id}
            value={item.id}
            label={(
              <FormattedMessageChildren {...messages.SORTING_OPTIONS}>
                {message => (
                  <FormattedMessageChildren {...item.name}>
                    {name => `${message} : ${name}`}
                  </FormattedMessageChildren>
                )}
              </FormattedMessageChildren>
            )}
          >
            <FormattedMessage {...item.name} />
          </Option>
        ))}
      </Select>
    )}
  </>
);

ProductSort.propTypes = {
  filters: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  ).isRequired,
  sortingOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.object.isRequired,
  })),
  onSetFilters: PropTypes.func,
  onChange: PropTypes.func,
};

ProductSort.defaultProps = {
  sortingOptions: [],
  onSetFilters: noop,
  onChange: noop,
};

export default enhancer(ProductSort);
