import connect from 'react/hoc/connectProxy';
import { compose, lifecycle } from 'recompose';
import {
  selectDomainsGroupedByCommunities,
  selectCommunityListWithDomains,
} from 'redux/communities/selectors';
import { getCommunities } from 'redux/communities/actions';

export default compose(
  connect(state => ({
    communities: selectCommunityListWithDomains(state, true),
    domainsCount: Object.keys(selectDomainsGroupedByCommunities(state)).length,
  }), {
    getCommunities: () => getCommunities({ populateDomains: true }),
  }),

  lifecycle({
    /** Load communities on mount. */
    componentDidMount() {
      this.props.getCommunities();
    },
  }),
);
