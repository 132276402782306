import * as BusinessUnitsService from 'services/businessUnits';
import { makeBufferedAction } from 'services/redux/bufferedAction';
import { ACTIONS } from './constants';

export const GET_BUSINESS_UNITS = ids => ({
  type: ACTIONS.GET_BUSINESS_UNITS,
  ids,
});

export const GET_BUSINESS_UNITS_SUCCESS = (ids, businessUnits, count) => ({
  type: ACTIONS.GET_BUSINESS_UNITS_SUCCESS,
  ids,
  businessUnits,
  count,
});

export const GET_BUSINESS_UNITS_FAILURE = (ids, error) => ({
  type: ACTIONS.GET_BUSINESS_UNITS_FAILURE,
  ids,
  error,
});

export const getBusinessUnits = ({ ids = [] } = {}) => async (dispatch) => {
  dispatch(GET_BUSINESS_UNITS(ids));

  try {
    const { businessUnits, count } = await BusinessUnitsService.getBusinessUnits({ ids });
    return dispatch(GET_BUSINESS_UNITS_SUCCESS(ids, businessUnits, count));
  } catch (err) {
    return dispatch(GET_BUSINESS_UNITS_FAILURE(ids, err));
  }
};

export const bufferedGetBusinessUnits = makeBufferedAction(
  getBusinessUnits,
  {
    transformParams: idsSet => [{ ids: Array.from(idsSet) }],
  },
);
