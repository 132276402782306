import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, pick } from 'lodash';
import queryString from 'query-string';

import { FILTERS } from 'react/business/facets/facets.constants';
import { defaultFilters } from 'react/business/facets/connect/withFiltersControllerInState';

import { PAGE_SIZE } from 'config/constants';
import { locationShape } from 'shapes/router';
import filtersShape from 'shapes/filters';

export default ({ pageSize = PAGE_SIZE } = {}) => (WrappedComponent) => {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  return class PaginatedProducts extends Component {
    static displayName = `PaginatedProducts(${componentName})`;

    static WrappedComponent = WrappedComponent;

    static propTypes = {
      location: locationShape.isRequired,
      getProducts: PropTypes.func,
      cachedSearchFiters: filtersShape,
      externalId: PropTypes.string,
    };

    static defaultProps = {
      externalId: '',
      getProducts: noop,
      cachedSearchFiters: defaultFilters,
    };

    /**
     * Get the list of products on first mount.
     */
    componentDidMount() {
      this.onLoadPage(
        0,
        { preserve: false },
      );
    }

    /**
     * Restart search.
     *
     * @param {object} prevProps - Prev props.
     */
    componentDidUpdate(prevProps) {
      if (prevProps.location.key !== this.props.location.key) {
        this.onLoadPage(
          0,
          { preserve: false },
        );
      }
    }

    onLoadPage = (page, { preserve = true, currentCount = 0 } = {}) => {
      const params = queryString.parse(this.props.location.search);
      const paramsFiltered = pick(params, ['q'].concat(Object.values(FILTERS)));

      const transformParamsFiltered = {
        ...paramsFiltered,
        ...this.props.cachedSearchFiters,
      };

      this.props.getProducts({
        // Search params.
        ...transformParamsFiltered,
        // ExternalId
        externalId: this.props.externalId,
        // Pagination.
        offset: currentCount,
        limit: pageSize,
        // Make sure to add the products to the list instead of replacing the list.
        preserve,
      });
    };

    /** @returns {object} JSX. */
    render() {
      return (
        <WrappedComponent
          {...this.props}
          onLoadPage={this.onLoadPage}
        />
      );
    }
  };
};
