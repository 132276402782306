import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from 'recompose';
import cn from 'classnames';

import { productShape } from 'shapes/product';

import withMediumBreakpoint from 'react/hoc/withMediumBreakpoint';

import { PulseSpinner } from 'react-spinners-kit';
import theme from 'style/theme.variables.scss';
import classNames from './product-grid.module.scss';
import ProductCard from '../card/ProductCard';

const enhancer = compose(
  withMediumBreakpoint,

  // Make the grid compact by default under medium breakpoint.
  mapProps(({ compact, isBelowMediumBreakpoint, ...props }) => ({
    ...props,
    compact: compact || isBelowMediumBreakpoint,
  })),

  memo,
);

const ProductGrid = ({
  products,
  className,
  compact,
  loading,
}) => (
  <>
    { !loading && (
    <div className={cn(className, classNames.grid, compact && classNames.compact)}>
      { products.map(product => (
        <ProductCard
          key={product.id}
          product={product}
          className={classNames.card}
          compact={compact}
        />
      ))}
    </div>
    )}
    {loading && (
      <div className={classNames.noContentContainer}>
        <PulseSpinner color={theme.primary} />
      </div>
      )}
  </>
);

ProductGrid.displayName = 'ProductGrid';

ProductGrid.propTypes = {
  products: PropTypes.arrayOf(productShape).isRequired,
  className: PropTypes.string,
  compact: PropTypes.bool,
};

ProductGrid.defaultProps = {
  className: null,
  compact: false,
};

export default enhancer(ProductGrid);
