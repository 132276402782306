import agent from 'services/http';
import { mapBusinessUnitFromServer } from './businessUnits.mapper';

/**
 * GET /api/businessUnits.
 * Get the list of businessUnits.
 *
 * @async
 * @param {object} query - Query parameters.
 * @returns {Array<object>} List of business units.
 */
export async function getBusinessUnits(query = {}) {
  const { body: { businessUnits, count } } = await agent
    .get('/api/businessUnits')
    .query(query);

  return {
    count,
    businessUnits: businessUnits.map(mapBusinessUnitFromServer),
  };
}
