import React, { memo } from 'react';
import { isEmpty } from 'lodash';

import { elementShape } from 'shapes/vendor';

import { FormattedMessage } from 'react-intl';
import AdvancedFiltersButton from '../controls/advancedFilters/AdvancedFiltersButton';
import ResetButton from '../controls/reset/ResetButton';

import messages from './filters.messages';
import classNames from './filtersWithContentLayout.module.scss';

const Filters = ({
  filtersComponent: FiltersComponent,
}) => (
  <FiltersComponent>
    {({
      commonFilters,
      advancedFilters,
      sortFilter,
      onResetFilters,
      shouldShowMoreFilters,
      onShowMoreFilters,
      onHideMoreFilters,
    }) => (
      <>
        <h2>
          <FormattedMessage {...messages.FILTER_OPTIONS} />
        </h2>

        <div className={classNames.filtersContainer}>
          <div className={classNames.left}>
            {commonFilters}
            {shouldShowMoreFilters && advancedFilters}

            {!isEmpty(advancedFilters) && (
              <div>
                <AdvancedFiltersButton
                  shouldShowMoreFilters={shouldShowMoreFilters}
                  onHideMoreFilters={onHideMoreFilters}
                  onShowMoreFilters={onShowMoreFilters}
                />
              </div>
            )}
          </div>

          <div className={classNames.right}>
            {sortFilter}

            { onResetFilters && (
              <div>
                <ResetButton onResetFilters={onResetFilters} />
              </div>
            ) }
          </div>
        </div>

      </>
    )}
  </FiltersComponent>
);

Filters.displayName = 'Filters';

Filters.propTypes = {
  filtersComponent: elementShape.isRequired,
};

export default memo(Filters);
